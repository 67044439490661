import React from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import mixpanel from 'mixpanel-browser'
import CONFIG from "./helpers/config";

// Initialize Mixpanel
mixpanel.init(CONFIG.MIXPANEL_PROJECT_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage'
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
