import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  RefObject,
} from "react";
import styles from "./DashboardOverview.module.scss";
import WebTransactionView from "../webTransactionView/WebTransactionView";
import TopUp from "../../features/topUp/TopUp";
import OverviewComp from "../overviewComp/OverviewComp";
import Support from "../../features/support/Support";
import Withdraw from "../../features/withdraw/Withdraw";
import LoanOptions from "../../features/loanOptions/LoanOptions";
import { getData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import CompleteVirtualAccountModal from "../../features/completeVirtualAcctModal/CompleteVirtualAcctModal";
import { decrypt } from "../../../../helpers/encryptor";
import { WalletTransactions } from "../../../../interfaces/transactions";
import TransactionDetails from "../../features/transactionDetails/TransactionDetails";
import useFetchUserTransaction from "../../../../custom-hooks/useFetchUserTransaction";
import { WalletDetails } from "../../../../interfaces/wallet";
import QuickLoanRepayment from "../../features/quickRepayment/QuickLoanRepayment";
import { useSelector, useDispatch } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../redux/actions";
import toast, { Toaster } from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import NewLoanOffer from "../../features/newLoanOffer/NewLoanOffer";
import { useFetchPendingLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";

interface OverviewProp {
  walletDetails: WalletDetails;
  refetchFetchWalletdetails: () => void;
  isLoadingWalletDetails: boolean;
  userExistsData: any;
  isRefetchingLoanDetails: () => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  isLoadingUserExist: boolean;
  handleBankStatement: () => any;
  isLoadingBankStatementLinkedStatus: boolean;
  bankStatementLinkedStatus: any;
  setCurrentLoanOptionStep: Dispatch<SetStateAction<number>>;
  currentLoanOptionStep: number;
}

function Overview({
  walletDetails,
  isLoadingWalletDetails,
  isLoadingUserExist,
  refetchFetchWalletdetails,
  userExistsData,
  isRefetchingLoanDetails,
  setPageNumber,
  pageNumber,
  handleBankStatement,
  isLoadingBankStatementLinkedStatus,
  bankStatementLinkedStatus,
  setCurrentLoanOptionStep,
  currentLoanOptionStep,
}: OverviewProp) {
  const transactionHistoryRef = useRef<HTMLDivElement | null>(null);
  const overviewRef = useRef<HTMLDivElement | null>(null);
  const [walletConsentDetails, setWalletConsentDetails] = useState<any>();
  const [transactionDetail, setTransactionDetail] = useState<
    WalletTransactions[] | null
  >(null);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  useEffect(() => {
    mixpanel.track_pageview({ page: "Dashboard - Web" });
  }, []);

  const {
    data: transactions,
    isLoading: isTransactionLoading,
    refetch: refetchTransactions,
  } = useFetchUserTransaction({
    accountNumber: userInfo?.accountNumber,
  });

  const { data: pendingLoanOffer, isLoading: isLoadingPendingLoanOffer } =
    useFetchPendingLoanOffer({
      customer_id: userInfo?.customerId,
    });

  const scrollToTransactions = (elementRef: RefObject<HTMLDivElement>) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = (elementRef: React.RefObject<HTMLDivElement>) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  async function fetchWalletConsent() {
    dispatch(updateWebSettingsAction({ walletConsentIsLoading: true }));
    try {
      const response = await getData(
        `${apiEndpoints.getWalletConsentWeb}?customerId=${userInfo?.customerId}`,
      );
      if (response.data) {
        setWalletConsentDetails(response.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? error.message);
    } finally {
      dispatch(updateWebSettingsAction({ walletConsentIsLoading: false }));
    }
  }

  useEffect(() => {
    if (userInfo?.customerId) {
      fetchWalletConsent().finally();
    }
    //eslint-disable-next-line
  }, [userInfo?.customerId, webStateData?.showVirtualAcctCompletionModal]);

  useEffect(() => {
    setTimeout(() => {
      if (!isLoadingPendingLoanOffer && pendingLoanOffer?.length > 0) {
        dispatch(updateWebSettingsAction({ showNewLoanOffer: true }));
      }
    }, 2000);
  }, [pendingLoanOffer, isLoadingPendingLoanOffer]);

  return (
    <div className={styles.overview_container} ref={overviewRef}>
      {webStateData?.showTransactions && (
        <WebTransactionView
          transactions={transactions}
          isTransactionLoading={isTransactionLoading}
          setTransactionDetail={setTransactionDetail}
        />
      )}

      {webStateData?.showLoanOptions && (
        <LoanOptions
          userExistsData={userExistsData}
          isRefetchingLoanDetails={isRefetchingLoanDetails}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          handleBankStatement={handleBankStatement}
          isLoadingBankStatementLinkedStatus={
            isLoadingBankStatementLinkedStatus
          }
          bankStatementLinkedStatus={bankStatementLinkedStatus}
          currentLoanOptionStep={currentLoanOptionStep}
          setCurrentLoanOptionStep={setCurrentLoanOptionStep}
        />
      )}

      {webStateData?.showQuickRepayment && <QuickLoanRepayment />}

      {webStateData?.showTopUp && (
        <TopUp
          walletDetails={walletDetails}
          refetchFetchWalletdetails={refetchFetchWalletdetails}
        />
      )}
      {webStateData?.showSupport && <Support />}

      {webStateData?.showWithdraw && (
        <Withdraw
          refetchTransactions={refetchTransactions}
          refetchFetchWalletdetails={refetchFetchWalletdetails}
          walletDetails={walletDetails}
        />
      )}

      {webStateData?.showVirtualAcctCompletionModal && (
        <CompleteVirtualAccountModal
          borrowerId={userInfo?.customerId}
          aggregatorId={userInfo?.aggregatorId}
          bvn={decrypt(userInfo?.bvn)}
          walletConsentDetails={walletConsentDetails}
        />
      )}

      {webStateData?.showTransactionDetail && (
        <TransactionDetails
          setTransactionDetail={setTransactionDetail}
          transactionDetail={transactionDetail}
        />
      )}

      {webStateData?.showNewLoanOffer && <NewLoanOffer />}

      {!webStateData?.showTransactions && (
        <OverviewComp
          transactionHistoryRef={transactionHistoryRef}
          overviewRef={overviewRef}
          scrollToTransactions={scrollToTransactions}
          scrollToTop={scrollToTop}
          walletConsentDetails={walletConsentDetails}
          isTransactionLoading={isTransactionLoading}
          setTransactionDetail={setTransactionDetail}
          refetchFetchWalletdetails={refetchFetchWalletdetails}
          isLoadingWalletDetails={isLoadingWalletDetails}
          walletDetails={walletDetails}
          userExistsData={userExistsData}
          isLoadingUserExist={isLoadingUserExist}
          transactions={transactions}
        />
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default Overview;
