import React, { useEffect, useState } from "react";
import styles from "./Support.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import CustomTextArea from "../../../../components/CustomHTMLElements/CustomTextArea";
import useFetchCountries from "../../../../custom-hooks/useFetchCountries";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { Button } from "antd";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import cleanPhoneSavedPhoneNo from "../../../../helpers/cleanSavedPhoneNumber";
import mixpanel from "mixpanel-browser";

const schema = yup.object().shape({
  email: yupValidators.email,
  phoneNumber: yupValidators.phone_number,
  fullName: yupValidators.fullName,
  note: yupValidators.note,
});

interface CountryCode {
  country_code?: string;
  currency?: string;
  dial_code?: string;
  id?: number;
  is_active?: number;
  name?: string;
}

interface FormData {
  fullName: string;
  email: string;
  note: string;
  phoneNumber: string;
}

function Support() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState<CountryCode[]>([]);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>({
    country_code: "ng",
    dial_code: "234",
  });
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const [userQuery, setUserQuery] = useState({
    full_name: `${userInfo?.first_name} ${userInfo?.last_name}`,
    phone_number: cleanPhoneSavedPhoneNo(userInfo),
    email: userInfo?.email,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (Support.tsx)",
      isLoggedIn: true,
    });

    mixpanel.track_pageview({ page: "Support" });
  }, []);

  const { data: countryLists } = useFetchCountries();

  useEffect(() => {
    setSelectedCountry({
      country_code: userInfo?.country?.toLowerCase(),
      dial_code: userInfo?.phoneNumberCountryDialCode,
    });
  }, [userInfo?.phoneNumberCountryDialCode, userInfo?.country]);

  const handleButtonClick = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleCountryDisplay = () => {
    setDisplayCountry((prev) => !prev);
  };

  const handleCountrySelection = (country: CountryCode) => {
    setSelectedCountry({
      country_code: country?.country_code?.toLowerCase(),
      dial_code: String(country.dial_code),
    });
    setDisplayCountry(false);
  };

  const filterPrompts = (searchText: string) => {
    const regex = new RegExp(searchText, "i");

    return countryLists.filter((country: CountryCode) => {
      const name = country.name || "";
      const countryCode = country.country_code || "";

      return regex.test(name) || regex.test(countryCode);
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout as NodeJS.Timeout);
    setSearchText(e.target.value);

    //debounce method
    setSearchTimeout(
      setTimeout(() => {
        const searchResult = filterPrompts(e.target.value);
        setSearchedResults(searchResult);
      }, 500),
    );
  };

  const onSubmit = async ({ fullName, email, phoneNumber, note }: FormData) => {
    setIsLoading(true);
    const reqBody = {
      customer_id: userInfo?.customerId,
      name: fullName,
      email,
      phone_number: phoneNumber,
      message: note,
    };
    try {
      await postData(apiEndpoints.contactUs, reqBody);
      handleButtonClick();
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (Support.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";

    dispatch(updateWebSettingsAction({ showSupport: false }));
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.stepOne_container}>
            {error && (
              <div
                style={{
                  width: "100%",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Alert
                  message={error}
                  type="error"
                  showIcon
                  closable
                  onClose={() => setError("")}
                />
              </div>
            )}
            <header className={styles.stepOne_header}>
              <h3>Contact Support</h3>
            </header>

            <form
              className={styles.stepOne_form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <CustomInputField
                label="Full Name"
                type="text"
                reference={register}
                placeholder="Enter your name"
                name="fullName"
                value={userQuery.full_name}
                errors={errors.fullName}
                onChange={(e: any) =>
                  setUserQuery({ ...userQuery, full_name: e.target.value })
                }
              />

              <div className={styles.verify_phone}>
                <label htmlFor="phone_number" className={styles.phone_label}>
                  Phone Number
                </label>
                <div className={styles.selected_option}>
                  <div className={styles.flag} onClick={handleCountryDisplay}>
                    <Icon
                      icon={`flag:${selectedCountry.country_code?.toLowerCase()}-4x3`}
                      className={styles.iconify}
                    />
                    <span>{selectedCountry.dial_code}</span>
                    {displayCountry ? (
                      <IoIosArrowUp className={styles.arrow} />
                    ) : (
                      <IoIosArrowDown className={styles.arrow} />
                    )}
                  </div>
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="07000000000"
                    ref={register}
                    value={userQuery.phone_number}
                    onChange={(e: any) =>
                      setUserQuery({
                        ...userQuery,
                        phone_number: e.target.value,
                      })
                    }
                  />
                </div>
                {displayCountry && (
                  <div className={styles.options}>
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearchChange}
                      className={styles.search_box}
                      placeholder="Search Country Name"
                    />
                    <ol>
                      {searchText
                        ? searchedResults?.map((country) => (
                            <li
                              key={country.name}
                              onClick={() => handleCountrySelection(country)}
                            >
                              <div>
                                <Icon
                                  icon={`flag:${country.country_code?.toLowerCase()}-4x3`}
                                  className={styles.iconify}
                                />
                                <span>{country.name}</span>
                              </div>
                              <span>{country.dial_code}</span>
                            </li>
                          ))
                        : countryLists?.map((country: CountryCode) => (
                            <li
                              key={country.name}
                              onClick={() => handleCountrySelection(country)}
                            >
                              <div>
                                <Icon
                                  icon={`flag:${country?.country_code?.toLowerCase()}-4x3`}
                                  className={styles.iconify}
                                />
                                <span>{country.name}</span>
                              </div>
                              <span>{country.dial_code}</span>
                            </li>
                          ))}
                    </ol>
                  </div>
                )}
                <span className={styles.phoneNumberValidation_error}>
                  {errors.phoneNumber?.message}
                </span>
              </div>

              <CustomInputField
                type="email"
                placeholder="example@mail.com"
                label="Email address"
                name="email"
                errors={errors.email}
                reference={register}
                value={userQuery.email}
                onChange={(e: any) =>
                  setUserQuery({ ...userQuery, email: e.target.value })
                }
              />

              <CustomTextArea
                label="Note"
                placeholder="Type your message"
                name="note"
                errors={errors.note}
                reference={register}
              />

              <div className={styles.submit__btn}>
                <Button type="primary" htmlType="submit" disabled={isLoading}>
                  {isLoading ? "Submitting inquiry..." : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <div className={styles.verification_success}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="75"
                viewBox="0 0 75 75"
                fill="none"
              >
                <path
                  d="M37.4856 0C58.1739 0 74.9712 16.7973 74.9712 37.4856C74.9712 58.1739 58.1739 74.9712 37.4856 74.9712C16.7973 74.9712 0 58.1739 0 37.4856C0 16.7973 16.7973 0 37.4856 0Z"
                  fill="#404040"
                />
                <path
                  d="M37.4637 3.54529C56.1953 3.54529 71.404 18.7539 71.404 37.4856C71.404 56.2172 56.1953 71.4258 37.4637 71.4258C18.7321 71.4258 3.52344 56.2172 3.52344 37.4856C3.52344 18.7539 18.7321 3.54529 37.4637 3.54529Z"
                  fill="white"
                />
                <path
                  d="M22.1348 38.7302L31.3106 47.6975L52.7905 26.2177"
                  stroke="#00C83C"
                  strokeWidth="3.75377"
                />
              </svg>
              <h3>Ticket Submitted</h3>
            </div>

            <div className={styles.submit__btn}>
              <Button type="primary" htmlType="submit" onClick={handleClose}>
                Done
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <div className={styles.modal_steps_wrapper}>
          {renderContent()}

          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default Support;
