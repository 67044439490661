import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import styles from "./LoanRequest.module.scss";
import { formatCurrency } from "../../../../helpers/formatCurrency";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { LoanProduct } from "../../../../interfaces/loan";

interface LoanRequestProps {
  products: LoanProduct[];
  handleCancel: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  cards: string[];
  existingDirectDebitAccountData: any;
  residentialInfoStatus: any;
}

function LoanRequestStepThree({
  products,
  handleCancel,
  cards,
  setCurrentStep,
  existingDirectDebitAccountData,
  residentialInfoStatus,
}: LoanRequestProps) {
  const [viewMore, setViewMore] = useState(false);
  const dispatch = useDispatch();
  const loanData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer,
  );
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer,
  );

  // const handleNext = () => {
  //   if (cards?.length === 0) {
  //     setCurrentStep(7);
  //   } else {
  //     setCurrentStep(3);
  //   }
  // };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestStepThree.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const specificProduct = products?.find(
    (product: { id: number }) => product.id === loanData?.product_id,
  );

  // repayment channel id === 6 means direct debit
  useEffect(() => {
    if (
      specificProduct?.repayment_channel_id === 6 ||
      specificProduct?.repayment_channel_id === 7
    ) {
      dispatch(updateWebSettingsAction({ IsDirectDebit: true }));
    } else {
      dispatch(updateWebSettingsAction({ IsDirectDebit: false }));
    }
  }, [specificProduct]);

  function loanAcceptanceHandler() {
    if (webStateData?.IsDirectDebit) {
      if (existingDirectDebitAccountData?.bank_accounts.length > 0) {
        setCurrentStep(8);
      } else {
        setCurrentStep(11);
      }
    } else if (
      !webStateData?.IsDirectDebit &&
      (!cards || cards?.length === 0)
    ) {
      setCurrentStep(7);
    } else if (
      !webStateData?.IsDirectDebit &&
      cards &&
      cards.length > 0 &&
      !residentialInfoStatus
    ) {
      setCurrentStep(12);
    } else {
      setCurrentStep(3);
    }
  }

  const handleScheduleView = () => {
    setViewMore((prev) => !prev);
  };

  return (
    <div className={styles.stepThree_container}>
      <header className={styles.stepThree_header}>
        <h3>Loan Offer</h3>
        <p>
          Based on the selected loan product, you are eligible for this offer.
        </p>
      </header>

      <div className={styles.loanOffer_container}>
        <div className={styles.loanOffer_overview}>
          <h4 className={styles.loan_title}>{loanData?.product_name}</h4>
          <span>
            <small>Loan Amount</small>
            <strong>{`${loanData?.currency?.code}${formatCurrency(
              loanData?.totalPrincipalDisbursed || 0,
            )}`}</strong>
          </span>
          <span>
            <small>Loan Tenor</small>
            <strong>{`${loanData?.loanTermInDays} days`}</strong>
          </span>
          <span>
            <small>Total Repayment</small>
            <strong>{`${loanData?.currency?.code}${formatCurrency(
              loanData?.totalRepaymentExpected || 0,
            )}`}</strong>
          </span>
          <span>
            <small>Loan Interest</small>
            <strong>{`${loanData?.currency?.code}${formatCurrency(
              loanData?.totalInterestCharged || 0,
            )}`}</strong>
          </span>
          <span>
            <small>Repayment Frequency</small>
            <strong>{loanData?.repayment_type}</strong>
          </span>
        </div>

        <div className={styles.loan_schedule}>
          <h4 className={styles.title}>Loan Schedule</h4>

          {viewMore
            ? loanData?.periods?.map((schedule: any, index) => (
                <div key={index} className={styles.loan_schedule_data}>
                  <span className={styles.serial_number}>{index + 1}</span>
                  <span className={styles.schedule_amount_date}>
                    <strong>
                      ₦{schedule?.totalDueForPeriod.toLocaleString()}
                    </strong>
                    <small>{`${schedule?.dueDate[2]}-${schedule?.dueDate[1]}-${schedule?.dueDate[0]}`}</small>
                  </span>
                </div>
              ))
            : loanData?.periods.slice(0, 6).map((schedule: any, index) => (
                <div key={index} className={styles.loan_schedule_data}>
                  <span className={styles.serial_number}>{index + 1}</span>
                  <span className={styles.schedule_amount_date}>
                    <strong>
                      ₦{schedule?.totalDueForPeriod.toLocaleString()}
                    </strong>
                    <small>{`${schedule?.dueDate[2]}-${schedule?.dueDate[1]}-${schedule?.dueDate[0]}`}</small>
                  </span>
                </div>
              ))}

          {loanData!.periods?.length > 6 && (
            <div className={styles.view_more_schedule_btn_container}>
              <button
                className={styles.view_more_schedule_btn}
                onClick={handleScheduleView}
              >
                {viewMore ? "View Less" : "View All Schedule"}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className={styles.buttons}>
        <Button type="primary" onClick={loanAcceptanceHandler}>
          Accept
        </Button>

        <Button type="primary" ghost onClick={handleCancel}>
          Decline
        </Button>
      </div>
    </div>
  );
}

export default LoanRequestStepThree;
