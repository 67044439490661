import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import LoanRequestStepOne from "./LoanRequestStepOne";
import LoanRequestStepTwo from "./LoanRequestStepTwo";
import LoanRequestStepThree from "./LoanRequestStepThree";
import LoanRequestStepFour from "./LoanRequestStepFour";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import LoanRequestDirectDebit from "./LoanRequestDirectDebit";
import { sendResponseToClient } from "../../../../../../helpers";
import { WIDGET_RESPONSE_LOAN_STATUSES } from "../../../../utils";
import useDirectDebitExistingAccounts from "../../../../../../custom-hooks/useDirectDebitExistingAccounts";
import WidgetBankStatementLinkageErrorScreen from "./WidgetBankStatementLinkageErrorScreen";
import WidgetAlternativeLoanCompleteScreen from "./WidgetAlternativeLoanCompleteScreen";
import DirectDebit from "../../../../DirectDebit/DirectDebit";
import { ISuccess } from "../../../../../../interfaces/success";
import { useSelector, useDispatch } from "react-redux";
import ResidentialInformation from "./ResidentialInformation";
import { useFetchCustomerResidentialInfoStatusWidget } from "../../../../../../custom-hooks/useFetchCustomerResidentialInfoStatus";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import mixpanel from "mixpanel-browser";
import { useFetchProductsWidget } from "../../../../../../custom-hooks/useFetchProducts";
import {
  updateLoanDetailsAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";

const LoanRequest = () => {
  const dispatch = useDispatch();
  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const { customer_id, currency, aggregator_id } = borrowerDetails;
  const { use_wallet, bankList, encryptedKey, isDirectDebit } = widgetState;
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<ISuccess>({
    headerText: "",
    bodyText: "",
  });

  const {
    data: residentialInfoStatus,
    isLoading: isLoadingResidentialInfoStatus,
  } = useFetchCustomerResidentialInfoStatusWidget({
    customer_id: customer_id,
    aggregator_id: aggregator_id,
  });

  const handleLoanRequestSuccess = (loanRequestResponse: any) => {
    sendResponseToClient({
      status: WIDGET_RESPONSE_LOAN_STATUSES.loan_request,
      data: { message: loanRequestResponse },
    });
  };

  useEffect(() => {
    appInsights.trackPageView({ name: "LoanRequest.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    mixpanel.track_pageview({ page: "Loan - Widget" });
  }, []);

  const handleNext = () => {
    if (currentStep < 7) {
      setCurrentStep((prevState) => prevState + 1);
    } else {
      dispatch(updateWidgetSettingsAction({ isGlobalLoader: true }));
      setTimeout(() => {
        dispatch(updateWidgetSettingsAction({ isGlobalLoader: false }));
        setShowSuccess(true);
      }, 2000);
    }
  };

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    } else {
      history.push(ROUTES.widgetLoan);
    }
  };

  function directDebitBacknavigation() {
    setError("");
    setSuccess({ ...success, headerText: "", bodyText: "" });

    if (existingDirectDebitAccountData?.bank_accounts?.length > 0) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }

  const handleGoBackAndClear = () => {
    dispatch(
      updateLoanDetailsAction({
        id: 0,
        product_name: "",
        maximum_tenor: null,
        maximum_amount: null,
        aggregator_id: 0,
        amount: 0,
        repayment_type: "",
      }),
    );
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    } else {
      history.push(ROUTES.widgetLoan);
    }
  };

  const cancelLoanRequestHandler = () => {
    dispatch(
      updateLoanDetailsAction({
        id: 0,
        product_name: "",
        maximum_tenor: null,
        maximum_amount: null,
        aggregator_id: 0,
        amount: 0,
        repayment_type: "",
      }),
    );
    history.push(ROUTES.widgetLoan);
  };

  function handleInputClearAndRoute() {
    dispatch(updateLoanDetailsAction({ repayment_type: "" }));
    history.push(ROUTES.widgetLoan);
  }

  const {
    data: existingDirectDebitAccountData,
    isLoading: directDebitLoading,
    error: directdebitError,
  } = useDirectDebitExistingAccounts({
    customerId: customer_id,
  });

  const { data: loanProducts, isLoading: isLoadingLoanProducts } =
    useFetchProductsWidget({
      aggregator_id: borrowerDetails.aggregator_id,
      country_code: borrowerDetails.country_code,
    });

  const steps =
    currency === "NGN"
      ? // && use_wallet && borrowerDetails?.bank_account_number
        [
          // <LoanRequestStepOne
          //   walletDetails={walletDetails}
          //   handleGoBack={handleGoBack}
          //   handleNext={handleNext}
          //   currency={currency}
          // />,
          <LoanRequestStepTwo
            loanProducts={loanProducts}
            isLoadingLoanProducts={isLoadingLoanProducts}
            handleGoBackAndClear={handleGoBackAndClear}
            setCurrentStep={setCurrentStep}
            error={error}
            setError={setError}
            encryptedKey={encryptedKey}
          />,
          <LoanRequestStepThree
            loanProducts={loanProducts}
            handleNext={handleNext}
            handleGoBackAndClear={handleGoBackAndClear}
            setCurrentStep={setCurrentStep}
            existingDirectDebitAccountData={existingDirectDebitAccountData}
            currentStep={currentStep}
            residentialInfoStatus={residentialInfoStatus}
            isLoadingResidentialInfoStatus={isLoadingResidentialInfoStatus}
          />,
          <LoanRequestDirectDebit
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            existingDirectDebitAccountData={existingDirectDebitAccountData}
            directDebitLoading={directDebitLoading}
            directdebitError={directdebitError}
            setError={setError}
            error={error}
            setCurrentStep={setCurrentStep}
            residentialInfoStatus={residentialInfoStatus}
            isLoadingResidentialInfoStatus={isLoadingResidentialInfoStatus}
          />,

          <ResidentialInformation
            setError={setError}
            error={error}
            aggregator_id={aggregator_id}
            customer_id={customer_id}
            existingDirectDebitAccountData={existingDirectDebitAccountData}
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
            encryptedKey={encryptedKey}
            isLoanRequest={true}
          />,

          <LoanRequestStepFour
            handlecancelLoanRequest={cancelLoanRequestHandler}
            handleNext={handleNext}
            setCurrentStep={setCurrentStep}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            error={error}
            setError={setError}
            encryptedKey={encryptedKey}
          />,

          <WidgetBankStatementLinkageErrorScreen
            handleNext={handleNext}
            encryptedKey={encryptedKey}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            error={error}
            setError={setError}
            setSuccess={setSuccess}
            success={success}
          />,
          <WidgetAlternativeLoanCompleteScreen
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            encryptedKey={encryptedKey}
            handleGoBack={() => setCurrentStep(0)}
            error={error}
            setError={setError}
          />,
          <DirectDebit
            bankList={bankList}
            setError={setError}
            error={error}
            setSuccess={setSuccess}
            success={success}
            setCurrentStep={setCurrentStep}
          />,
        ]
      : [
          <LoanRequestStepTwo
            loanProducts={loanProducts}
            isLoadingLoanProducts={isLoadingLoanProducts}
            handleGoBackAndClear={handleGoBackAndClear}
            setCurrentStep={setCurrentStep}
            error={error}
            setError={setError}
            encryptedKey={encryptedKey}
          />,
          <LoanRequestStepThree
            loanProducts={loanProducts}
            handleNext={handleNext}
            handleGoBackAndClear={handleGoBackAndClear}
            setCurrentStep={setCurrentStep}
            existingDirectDebitAccountData={existingDirectDebitAccountData}
            currentStep={currentStep}
            residentialInfoStatus={residentialInfoStatus}
            isLoadingResidentialInfoStatus={isLoadingResidentialInfoStatus}
          />,

          <LoanRequestStepFour
            handlecancelLoanRequest={cancelLoanRequestHandler}
            handleNext={handleNext}
            setCurrentStep={setCurrentStep}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            error={error}
            setError={setError}
            encryptedKey={encryptedKey}
          />,
        ];

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body="Your request has been received and will be reviewed for approval"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }

  return (
    <div>
      <div
        onClick={() =>
          currentStep < 1
            ? handleInputClearAndRoute()
            : currentStep === 2
              ? setCurrentStep(currentStep - 1)
              : currentStep === 1
                ? handleGoBackAndClear()
                : currentStep === 3 &&
                    isDirectDebit &&
                    existingDirectDebitAccountData?.bank_accounts?.length > 0
                  ? setCurrentStep(2)
                  : currentStep === 3 &&
                      isDirectDebit &&
                      existingDirectDebitAccountData?.bank_accounts?.length ===
                        0
                    ? setCurrentStep(1)
                    : currentStep === 5
                      ? setCurrentStep(currentStep - 1)
                      : currentStep === 4
                        ? setCurrentStep(1)
                        : currentStep === 7 &&
                            existingDirectDebitAccountData?.bank_accounts
                              ?.length > 0
                          ? directDebitBacknavigation()
                          : currentStep === 7 &&
                              existingDirectDebitAccountData?.bank_accounts
                                ?.length === 0
                            ? directDebitBacknavigation()
                            : history.push(ROUTES.widgetLoan)
        }
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>{currentStep < 1 ? "Loan request" : null}</h4>
      {steps[currentStep]}
    </div>
  );
};

export default LoanRequest;
