import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Form, Input } from "antd";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { appInsights } from "../../../../AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";
import styles from "../QuickLoanRepayment.module.scss";
import advLogo from "../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../assets/loadinggif.gif";
import { getDataWithKey } from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { errorHandler, errorToast } from "../../../../../helpers/errorHandler";
import moment from "moment/moment";

const QuickLoanRepaymentAmount = () => {
  const [dueLoan, setDueLoan] = useState({
    dueAmount: "",
    dueDate: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation<any>();
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Quick Loan Repayment - QuickLoanRepayment.tsx",
      isLoggedIn: true,
    });

    mixpanel.track_pageview({ page: "QuickLoanRepayment - Widget" });
  }, []);

  async function fetchRepaymentSchedule() {
    setIsLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getLoanRepaymentSchedule}?loan_ref=${location?.state?.loanRef}&aggregator_id=${location?.state?.aggregatorId}`,
      );

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for today

      let dueLoanPeriod = response?.data?.periods?.find((loan: any) => {
        const loanDueDate = new Date(loan?.dueDate);
        loanDueDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for the loan due date

        return (
          loan?.totalDueForPeriod > 0 &&
          loanDueDate.getTime() >= today.getTime() &&
          loan?.complete === false
        );
      });

      if (!dueLoanPeriod) {
        const pastDueLoans = response?.data?.periods?.filter((loan: any) => {
          const loanDueDate = new Date(loan?.dueDate);
          loanDueDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for the loan due date

          return (
            loan?.totalDueForPeriod > 0 &&
            loanDueDate.getTime() < today.getTime() &&
            loan?.complete === false
          );
        });

        pastDueLoans?.sort((a: any, b: any) => {
          const dateA = new Date(a.dueDate).getTime();
          const dateB = new Date(b.dueDate).getTime();
          return dateB - dateA;
        });

        dueLoanPeriod = pastDueLoans?.[0]; // The first element is the most recent unpaid loan
      }

      if (dueLoanPeriod) {
        const dueDate = new Date(dueLoanPeriod.dueDate);
        const formattedDueDate = moment(dueDate).format("D/MM/YYYY");

        setDueLoan((prevState) => ({
          ...prevState,
          dueAmount:
            dueLoanPeriod.totalOutstandingForPeriod > 0
              ? dueLoanPeriod.totalOutstandingForPeriod
              : dueLoanPeriod.totalDueForPeriod,
          dueDate: formattedDueDate,
        }));
      }
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: QuickLoanRepaymentAmount },
      });
    }
  }

  useEffect(() => {
    fetchRepaymentSchedule().finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div>
      <button
        onClick={
          () => history.goBack()
          // history.push({
          //   pathname: ROUTES.widgetLoanHistory,
          // })
        }
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </button>
      <h4>Loan repayment</h4>
      <p>Enter the amount you would like to pay</p>
      <br />
      <div className={styles.dueAmountContainer}>
        <span className={styles.title}>Amount Due</span>
        {dueLoan?.dueAmount?.toLocaleString().split("").includes(".") ? (
          <span className={styles.credit_balance}>
            ₦<strong>{dueLoan?.dueAmount.toLocaleString()}</strong>
          </span>
        ) : (
          <span className={styles.credit_balance}>
            ₦<strong>{dueLoan?.dueAmount.toLocaleString()}.</strong>00
          </span>
        )}
        <span>Due Date: {dueLoan.dueDate}</span>
      </div>
      <br />
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) =>
          history.push({
            pathname: ROUTES.widgetLoanRepaymentMethods,
            state: {
              loan_id: location?.state?.id,
              amount: values.amount,
              loan_ref: location?.state?.loanRef,
            },
          })
        }
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="amount"
          label="Enter amount"
          rules={[
            { required: true, message: "Required field" },
            {
              pattern: /^\d*[.]?\d*$/,
              message: "Enter numbers from 0 - 9",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              role="button"
              aria-roledescription="button"
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: ROUTES.widgetQuickLoanRepayment,
                })
              }
            >
              Go Back
            </p>
            <button className="btn btn-primary btn-radius">Proceed</button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default QuickLoanRepaymentAmount;
