import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getData, postData } from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import MastercardIcon from "../../../../../assets/mastercard.svg";
import VisaIcon from "../../../../../assets/visa.svg";
import VerveIcon from "../../../../../assets/verve.svg";
import { BsCheck2All } from "react-icons/bs";
import AddCard from "../../../features/addCard/AddCard";
import styles from "./AddCardForm.module.scss";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../../redux/actions";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { useLocation } from "react-router-dom";
import advLogo from "../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../assets/loadinggif.gif";

export type CardProp = {
  card_id: number;
  customer_id: number;
  last_four_digits: number;
  card_type: string;
  expiry_month: number;
  expiry_year: number;
  is_default: boolean;
};
interface IProps {
  updatePayStackStatus: () => void;
  residentialInfoStatus: any;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const AddCardForm = ({
  updatePayStackStatus,
  residentialInfoStatus,
  setCurrentStep,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const location = useLocation();

  const [cards, setCards] = useState<CardProp[]>([]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (AddCardForm.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const cardImage = (type: string) => {
    const cardType = type.toLowerCase().trim();
    return cardType?.includes("mastercard") ? (
      <img
        src={MastercardIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("visa") ? (
      <img
        src={VisaIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : cardType?.includes("verve") ? (
      <img
        src={VerveIcon}
        alt="logo"
        style={{ width: "40px", height: "40px" }}
      />
    ) : null;
  };

  const fetchAddedCards = async () => {
    setIsLoading(true);
    try {
      const response = await getData(
        `${apiEndpoints.fetchCards}?borrower_id=${
          userInfo?.customerId
        }&aggregator_id=${userInfo?.aggregatorId}&isWidget=${false}`,
      );
      setCards(response.data);
      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
        }),
      );
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        }),
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (AddCardForm.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function makeCardDefault(item: CardProp) {
    setIsLoading(true);
    try {
      await postData(`${apiEndpoints.setDefaultCard}?isWidget=${false}`, {
        aggregator_id: userInfo.aggregatorId,
        borrower_id: userInfo.customerId,
        card_id: item.card_id,
      });
      await fetchAddedCards();
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        }),
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web - (AddCardForm.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        dispatch(updateWebSettingsAction({ errorMsg: "" }));
      }, 3000);
    }
  }

  useEffect(() => {
    fetchAddedCards().finally();
  }, []);

  function cardProcessDoneHandler() {
    if (!residentialInfoStatus) {
      setCurrentStep(12);
    } else {
      setCurrentStep(3);
    }
  }

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div>
      <h3>Cards</h3>

      {cards?.length === 0 ? (
        <>
          <h4 className="text-center">No Data Available</h4>
        </>
      ) : (
        cards?.map((item) => (
          <div key={item.card_id}>
            <div className="card">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className=" mr-3">{cardImage(item.card_type)}</div>
                  <div>
                    <p className="font-weight-bold text-dark padding-none">
                      {`**** **** **** ${item.last_four_digits}`}
                      <small className="ml-2 text-success">
                        {item.is_default && (
                          <span
                            className="text-success"
                            style={{ marginTop: "-4rem" }}
                          >
                            <BsCheck2All size="1.3rem" />
                          </span>
                        )}
                      </small>
                    </p>
                    <small className="text-muted padding-none">
                      {`${item.expiry_month}/${item.expiry_year}`}
                    </small>
                  </div>
                </div>

                {!item.is_default && (
                  <p
                    className="cursor padding-none"
                    onClick={() => makeCardDefault(item)}
                  >
                    Set Default
                  </p>
                )}
              </div>
            </div>
            <br />
          </div>
        ))
      )}

      <div
        className="d-flex justify-content-end"
        style={{ marginTop: "10%", gap: "10px" }}
      >
        {location.pathname !== `/${userInfo.aggregator_name}/profile` && (
          <button
            style={{ cursor: "pointer", color: "#200865" }}
            onClick={cardProcessDoneHandler}
          >
            {cards.length > 0 ? "Proceed" : "Skip"}
          </button>
        )}
        <div className={styles.btns}>
          <AddCard
            updatePayStackStatus={updatePayStackStatus}
            fetchAddedCards={fetchAddedCards}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCardForm;
