import React, { useEffect, useState } from "react";
import { ROUTES } from "../../../../../helpers/ROUTES";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { appInsights } from "../../../../AppInsight/AppInsight";
import { postDataWithKey } from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { ReduxStoreModel } from "../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

const ContactUs = () => {
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer
  );
  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer
  );
  const { encryptedKey } = widgetState;
  const { first_name, email, customer_id, borrower_phone } = borrowerDetails;
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => {
    appInsights.trackPageView({ name: "ContactUs.tsx", isLoggedIn: true });
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    mixpanel.track_pageview({"page": "Contact Us - Widget"});
  }, []);

  async function handleSubmit(values: {
    email: string;
    firstName: string;
    message: string;
    phone: string;
  }) {
    setIsLoading(true);
    try {
      await postDataWithKey(
        apiEndpoints.contactUs,
        { ...values, customer_id },
        //@ts-ignore
        encryptedKey
      );
      console.log({ values });
      message.success("Message sent successfully!");
      history.push(ROUTES.widgetDashboard);
    } catch (error) {
      console.log(error);
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "ContactUs.tsx" },
      });
      message.error("Something went wrong please try again");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Contact Support</h4>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="name"
          label="First Name"
          initialValue={first_name}
          rules={[{ required: true, message: "Please input your first name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="phone_number"
          label="Phone Number"
          initialValue={borrower_phone}
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="email"
          label="Email"
          initialValue={email}
          rules={[
            { required: true, message: "Please input your email!" },
            {
              type: "email",
              message: "Enter a valid email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="message"
          label="Message"
          rules={[{ required: true, message: "Please input your message!" }]}
        >
          <Input.TextArea
            id="textarea"
            showCount
            maxLength={300}
            rows={5}
            style={{ resize: "none" }}
          />
        </Form.Item>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() => history.push(ROUTES.widgetDashboard)}
            >
              Go Back
            </p>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit Message
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ContactUs;
