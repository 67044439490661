import { Alert, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useWidgetDirectDebitsApprovedAcct } from "../../../../../../custom-hooks/useDirectDebitsApprovedAcct";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../../../Loader/Loader";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { IoMdArrowRoundBack } from "react-icons/io";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import NewLoader from "../../../components/NewLoader";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

const LoanRepaymentViaDirectDebit = () => {
  let location = useLocation<any>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [approvedDirectDebitAccounts, setApprovedDirectDebitAccounts] =
    useState("");
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const data = localStorage.getItem("userData");
  const userInfo = data && JSON.parse(data);

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRepaymentViaDirectDebit.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: ApprovedDirectDebitData,
    isLoading: ApprovedDirectDebitLoading,
    error: ApprovedDirectDebitError,
  } = useWidgetDirectDebitsApprovedAcct({
    loanRef: location?.state?.loan_ref,
    clientRequest: true,
    isWidget: true,
    aggregator_id: borrowerDetails.aggregator_id
      ? borrowerDetails.aggregator_id
      : 0,
  });

  useEffect(() => {
    if (ApprovedDirectDebitData?.length === 1) {
      ApprovedDirectDebitData?.map((data: any) =>
        setApprovedDirectDebitAccounts(data?.id),
      );
    }
  }, [ApprovedDirectDebitLoading]);

  const handleRepaymentWithDirectDebit = async () => {
    setIsLoading(true);
    try {
      const response = await postDataWithKey(
        apiEndpoints.makeRepayment,
        {
          aggregator_id: borrowerDetails.aggregator_id,
          borrower_id: borrowerDetails.customer_id,
          loan_direct_debit_mandate_id: approvedDirectDebitAccounts,
          borrower_repayment_mode: "DirectDebit",
          loan_id: location?.state?.loan_id,
          amount: Number(location?.state?.amount),
        },
        // @ts-ignore
        borrowerDetails?.returningUserDetails?.encryptedKey,
      );

      if (response.status) {
        setSuccess(response.message);
        setDone(true);
        setError("");

        mixpanel.track("Repay loan", {
          distinct_id: `${userInfo?.email}`,
          "amount paid": `${Number(location?.state?.amount)}`,
          "repayment mode": `direct debit`,
          "repayment status": `repaid`,
        });
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: LoanRepaymentViaDirectDebit },
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  if (done) {
    return (
      <SuccessComponent
        btnText="Done"
        body={success}
        title="Repayment Successful!"
        handler={() => history.push(ROUTES.widgetDashboard)}
        type="success"
      />
    );
  }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "10px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}

      <div
        onClick={() => history.goBack()}
        className="cursor"
        style={{ marginBottom: "10px" }}
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <h5>Choose a Direct Debit account</h5>
        {ApprovedDirectDebitError && (
          <p className="text-danger ">Error fetching direct debit account</p>
        )}
        {ApprovedDirectDebitLoading ? (
          <Loader message="Loading..." />
        ) : (
          ApprovedDirectDebitData?.map((debitDetails: any) => {
            const {
              customer_bank_account_number,
              customer_bank_account_name,
              customer_bank_name,
              amount: directAmount,
              direct_debit_mandate_status,
              id,
            } = debitDetails;
            return (
              <>
                <div
                  key={id}
                  className="d-flex flex-column align-items-start justify-content-start my-3"
                  onClick={() => {
                    setApprovedDirectDebitAccounts(id);
                  }}
                  style={{
                    backgroundColor:
                      approvedDirectDebitAccounts === id
                        ? "#713efe"
                        : "#f7f5ff",
                    border:
                      approvedDirectDebitAccounts === id
                        ? "1px solid #713efe"
                        : "1px solid #f7f5ff",
                    borderRightColor:
                      approvedDirectDebitAccounts === id
                        ? "#cfbefe"
                        : "#f7f5ff",
                    borderRightWidth:
                      approvedDirectDebitAccounts === id ? "5px" : "1px",

                    borderRadius: "10px",
                    padding: "10px",
                    cursor: "pointer",
                    color: approvedDirectDebitAccounts === id ? "#fff" : "",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <span className="mr-1">Account Number: {""} </span>
                    <span>{""}</span>
                    <span style={{ fontWeight: "bold" }}>
                      {customer_bank_account_number}
                    </span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <span className="mr-1">Account Name: {""} </span>
                    <span style={{ fontWeight: "bold" }}>
                      {customer_bank_account_name}
                    </span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <span className="mr-1">Bank Name: {""}</span>
                    <span style={{ fontWeight: "bold" }}>
                      {customer_bank_name}
                    </span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <span className="mr-1">Amount: {""}</span>
                    <span style={{ fontWeight: "bold" }}>{directAmount}</span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <span className="mr-1">Mandate Status: {""}</span>
                    <span style={{ fontWeight: "bold" }}>
                      {direct_debit_mandate_status}
                    </span>
                  </div>
                </div>
              </>
            );
          })
        )}

        <Button
          type="primary"
          htmlType="submit"
          onClick={handleRepaymentWithDirectDebit}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default LoanRepaymentViaDirectDebit;
