import React, {useEffect, useState} from "react";
import styles from "./Profile.module.scss";
import PersonalInformation from "./personalInformation/PersonalInformation";
import Card from "./card/Card";
import TransactionPin from "./transactionPin/TransactionPin";
import Password from "./password/Password";
import nouser from "../../../assets/web/nouser.svg";
import edit from "../../../assets/web/edit-2.svg";
import arrowDown from "../../../assets/web/arrow-down.svg";
import DirectDebitAccountWeb from "./DirectDebitAccountsWeb/DirectDebitAccountWeb";
import BankStatement from "./bankStatement/BankStatement";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import mixpanel from "mixpanel-browser";

enum ProfileSection {
  PersonalInformation = "PersonalInformation",
  Card = "Card",
  DirectDebit = "DirectDebit",
  BankStatement = "BankStatement",
  TransactionPin = "TransactionPin",
  Password = "Password",
}

const Profile = ({
  walletDetails,
  handleBankStatement,
  isLoadingBankStatementLinkedStatus,
  bankStatementLinkedStatus,
}: any) => {
  const [currentSection, setCurrentSection] = useState<ProfileSection>(
    ProfileSection.PersonalInformation
  );
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  useEffect(() => {
    mixpanel.track_pageview({"page": "Profile - Web"});
  }, []);

  const renderProfileSection = () => {
    switch (currentSection) {
      case ProfileSection.PersonalInformation:
        return <PersonalInformation walletDetails={walletDetails} />;
      case ProfileSection.Card:
        return <Card />;
      case ProfileSection.DirectDebit:
        return <DirectDebitAccountWeb />;
      case ProfileSection.BankStatement:
        return (
          <BankStatement
            isLoadingBankStatementLinkedStatus={
              isLoadingBankStatementLinkedStatus
            }
            bankStatementLinkedStatus={bankStatementLinkedStatus}
            handleBankStatement={handleBankStatement}
          />
        );
      case ProfileSection.TransactionPin:
        return <TransactionPin walletDetails={walletDetails} />;
      case ProfileSection.Password:
        return <Password />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.profile_container}>
      {webStateData.errorMsg && (
        <div style={{ width: "100%", paddingBottom: "20px" }}>
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      {webStateData.successMsg.bodyText && (
        <div style={{ width: "100%", paddingBottom: "20px" }}>
          <Alert
            message={webStateData.successMsg.bodyText}
            type="success"
            showIcon
            closable
            onClose={() =>
              dispatch(
                updateWebSettingsAction({ successMsg: { bodyText: "" } })
              )
            }
          />
        </div>
      )}
      <header className={styles.profile_header}>
        <h1>Profile</h1>
      </header>

      <div className={styles.profile_menu_header}>
        <div className={styles.photo_wrapper}>
          <img
            src={userInfo?.photo_url ?? nouser}
            alt=""
            className={styles.photo}
          />
          <span className={styles.pen_holder}>
            <img src={edit} alt="" className={styles.pen} />
          </span>
        </div>
        <div className={styles.name_email}>
          {userInfo?.business_registration_number ? (
            <span>{userInfo?.company_name}</span>
          ) : (
            <span>
              {userInfo?.first_name} {userInfo?.last_name}
            </span>
          )}
          <span>{userInfo?.email}</span>
        </div>
      </div>

      <div className={styles.profile_content_wrapper}>
        <div className={styles.menu_content_wrapper}>
          <div className={styles.menu_wrapper}>
            <div
              className={`${styles.menu_item} ${
                currentSection === ProfileSection.PersonalInformation
                  ? styles.active
                  : ""
              }`}
              onClick={() =>
                setCurrentSection(ProfileSection.PersonalInformation)
              }
            >
              {userInfo?.business_registration_number
                ? "Business Information"
                : "Personal Information"}
            </div>
            <div
              className={`${styles.menu_item} ${
                currentSection === ProfileSection.Card ? styles.active : ""
              }`}
              onClick={() => setCurrentSection(ProfileSection.Card)}
            >
              Card
            </div>
            {userInfo.country === "NG" && (
              <div
                className={`${styles.menu_item} ${
                  currentSection === ProfileSection.DirectDebit
                    ? styles.active
                    : ""
                }`}
                onClick={() => setCurrentSection(ProfileSection.DirectDebit)}
              >
                Direct Debit Accounts
              </div>
            )}
            <div
              className={`${styles.menu_item} ${
                currentSection === ProfileSection.BankStatement
                  ? styles.active
                  : ""
              }`}
              onClick={() => setCurrentSection(ProfileSection.BankStatement)}
            >
              Bank Statement
            </div>
            <div
              className={`${styles.menu_item} ${
                currentSection === ProfileSection.TransactionPin
                  ? styles.active
                  : ""
              }`}
              onClick={() => setCurrentSection(ProfileSection.TransactionPin)}
            >
              Transaction Pin
            </div>
            <div
              className={`${styles.menu_item} ${
                currentSection === ProfileSection.Password ? styles.active : ""
              }`}
              onClick={() => setCurrentSection(ProfileSection.Password)}
            >
              Password
            </div>
          </div>

          <div className={styles.select}>
            <select
              value={currentSection}
              onChange={(e) =>
                setCurrentSection(e.target.value as ProfileSection)
              }
            >
              <option value={ProfileSection.PersonalInformation}>
                {userInfo?.business_registration_number
                  ? "Business Information"
                  : "Personal Information"}
              </option>
              <option value={ProfileSection.Card}>Card</option>
              {userInfo.country === "NG" && (
                <option value={ProfileSection.DirectDebit}>
                  Direct Debit Accounts
                </option>
              )}
              <option value={ProfileSection.BankStatement}>
                Bank Statement
              </option>
              <option value={ProfileSection.TransactionPin}>
                Transaction Pin
              </option>
              <option value={ProfileSection.Password}>Password</option>
            </select>

            <img src={arrowDown} alt="" />
          </div>
          <div className={styles.profile_content}>{renderProfileSection()}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
