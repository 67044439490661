import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Checkbox, message } from "antd";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import {
  postDataWithKey,
  putDotNetDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { SuccessComponent } from "../../../SuccessComponent";
import Loader from "../../../../../Loader/Loader";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import CONFIG from "../../../../../../helpers/config";
import MonoConnect from "@mono.co/connect.js";
import Okra from "okra-js";
import useFetchTermsAndConditions from "../../../../../../custom-hooks/useFetchTermsAndConditions";
import DOMPurify from "dompurify";
import {
  updateBorrowerDetailsAction,
  updateMonoWidgetDataAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";

interface LoanRequestStepFourProps {
  handleNext: () => void;
  handlecancelLoanRequest: () => void;
  handleLoanRequestSuccess: any;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  encryptedKey: string;
}

const LoanRequestStepFour = ({
  handlecancelLoanRequest,
  handleLoanRequestSuccess,
  setCurrentStep,
  error,
  setError,
  encryptedKey,
}: LoanRequestStepFourProps) => {
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isSavingProvider, setIsSavingProvider] = useState(false);
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const loanStateData = useSelector(
    (store: ReduxStoreModel) => store.loanDetailsReducer,
  );
  const monoWidgetData = useSelector(
    (state: ReduxStoreModel) => state.monoWidgetDataReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepFour.tsx",
      isLoggedIn: true,
    });
  }, []);
  const accountLinkSuccessRef = useRef<boolean | null>(null);

  function createMarkup(html: any) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return setError("Update Config for Okra");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      }),
    );

    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return setError("Update Config for Mono");
    }

    const customer = {
      name: `${borrowerDetails?.first_name} ${borrowerDetails?.last_name}`,
      email: borrowerDetails?.email,
    };
    const monoInstance = new MonoConnect({
      onClose: () => {
        if (!accountLinkSuccessRef.current) {
          setCurrentStep(5);
        } else {
          console.log("Widget closed successfully");
        }
        //Below appInsights is tracking the widget data
        appInsights.trackEvent({
          name: "MonoWidgetData",
          properties: {
            opened: monoWidgetData?.opened,
            opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
            customer_email: monoWidgetData?.customer_email,
            is_institution_selected: monoWidgetData?.is_institution_selected,
            selected_institution_name:
              monoWidgetData?.selected_institution_name,
            institution_selected_dateTimeStamp:
              monoWidgetData?.institution_selected_dateTimeStamp,
            AccountLinkStatus: accountLinkSuccessRef.current,
          },
        });
      },
      onLoad: () => console.log("Widget loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        accountLinkSuccessRef.current = true;
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          dispatch(
            updateMonoWidgetDataAction({
              opened: true,
              opened_dateTimeStamp: data?.timestamp,
              customer_email: borrowerDetails?.email,
            }),
          );
        } else if (eventName === "INSTITUTION_SELECTED") {
          dispatch(
            updateMonoWidgetDataAction({
              is_institution_selected: true,
              selected_institution_name: data.institution.name,
              institution_selected_dateTimeStamp: data?.timestamp,
            }),
          );
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    setError("");
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: borrowerDetails?.customer_id,
      aggregator_id: borrowerDetails?.aggregator_id,
    };

    try {
      setIsSavingProvider(true);
      await putDotNetDataWithKey(
        apiEndpoints.saveBankStatementProvider,
        reqBody,
        //@ts-ignore
        encryptedKey,
      );
      message.success("Bank provider details saved successfully");

      dispatch(
        updateBorrowerDetailsAction({
          bank_statement_connected: true,
        }),
      );

      await handleCreateLoan();
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "LoanRequestStepFour.tsx" },
      });
      setError(
        error?.response?.data?.message ??
          "Something went wrong please try again",
      );
    } finally {
      setIsSavingProvider(false);
    }
  };

  const handleBankStatementOrCreateLoan = () => {
    if (!borrowerDetails?.bank_statement_connected) {
      if (borrowerDetails?.bank_statement_provider?.toLowerCase() === "okra") {
        handleLaunchOkraWidget();
      } else {
        monoConnect.open();
      }
    } else {
      handleCreateLoan().finally();
    }
  };

  async function handleCreateLoan() {
    setError("");
    setSuccessMsg("");
    setIsLoading(true);
    setShowSuccess(false);

    const payload = {
      effectiveDate: loanStateData?.loan_effective_date,
      bvn_number: borrowerDetails?.bvn_number,
      business_registration_number:
        borrowerDetails?.business_registration_number,
      phone_number: borrowerDetails?.borrower_phone,
      product_id: loanStateData?.product_id,
      aggregator_loan_ref: loanStateData?.aggregator_loan_ref,
      loan_amount: loanStateData?.loan_amount,
      loan_tenure: loanStateData?.loan_tenure,
      repayment_start_date: loanStateData?.repayment_start_date,
      aggregator_id: borrowerDetails?.aggregator_id,
      annual_interest_rate: loanStateData?.annual_interest_rate,
      country_code: borrowerDetails?.country_code,
      customer_id: borrowerDetails?.customer_id,
      customer_bank_account: loanStateData?.customer_bank_account,
      loan_tenor_input_type: loanStateData?.loan_tenor_input_type,
      is_bankstatement_linkage_failed: false,
      bank_statement_provider_response: monoWidgetData?.opened
        ? JSON.stringify({
            opened: monoWidgetData?.opened,
            customer_email: monoWidgetData?.customer_email,
            opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
            is_institution_selected: monoWidgetData?.is_institution_selected,
            selected_institution_name:
              monoWidgetData?.selected_institution_name,
            institution_selected_dateTimeStamp:
              monoWidgetData?.institution_selected_dateTimeStamp,
          })
        : "linked",
    };

    //Below appInsights is tracking the loan  data
    appInsights.trackEvent({
      name: "WidgetCreateLoanData",
      properties: payload,
    });
    try {
      const loanRequestResponse = await postDataWithKey(
        apiEndpoints.processLoan,
        payload,
        //@ts-ignore
        encryptedKey,
      );

      if (loanRequestResponse?.status) {
        setSuccessMsg(loanRequestResponse?.message);
        setShowSuccess(true);

        mixpanel.track("Get Loan", {
          distinct_id: `${borrowerDetails?.email}`,
          "loan amount": `${loanStateData?.loan_amount}`,
          "loan name": `${loanStateData?.loan_name}`,
          "loan tenor": `${loanStateData?.loan_tenure}`,
          product_id: `${loanStateData?.product_id}`,
          "Loan status": `successfully applied`,
          "bank statement linked status": `linked`,
        });
      }

      if (handleLoanRequestSuccess) {
        handleLoanRequestSuccess(loanRequestResponse);
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: LoanRequestStepFour },
      });
    }
    setIsLoading(false);
  }

  const { data: termsAndConditions, isLoading: isLoadingTermsAndConditions } =
    useFetchTermsAndConditions({
      aggregatorId: String(borrowerDetails?.aggregator_id),
    });

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body={`${successMsg}`}
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }
  if (isLoading || isSavingProvider) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      <div>
        <h4>Terms and agreement</h4>
        <br />
        {isLoadingTermsAndConditions ? (
          <Loader message="Loading terms and condition..." />
        ) : (
          <div
            className="card"
            style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
          >
            <div className="card-body">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  termsAndConditions.termsAndConditions,
                )}
              ></div>
              <Checkbox
                className="text-muted"
                onChange={(e) => {
                  setAcceptTerms(e.target.checked);
                }}
              >
                I agree to these terms and condition.
              </Checkbox>
            </div>
          </div>
        )}

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handlecancelLoanRequest}
            >
              Cancel my request
            </p>
            <button
              className="btn btn-primary btn-radius"
              disabled={!acceptTerms}
              onClick={handleBankStatementOrCreateLoan}
            >
              I understand
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanRequestStepFour;
