import React, { useEffect, useRef } from "react";
import styles from "./LoanOverview.module.scss";
import WebTransactionView from "../webTransactionView/WebTransactionView";
import OverviewComp from "../overViewComp/OverviewComp";
import { fetchWebLoanTransactions } from "../../../../custom-hooks/useFetchLoanHistory";
import { useQuery, useQueryCache } from "react-query";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import mixpanel from "mixpanel-browser";

interface LoanOverviewProp {
  isLoadingUserExist: boolean;
  userExistsData: any;
  isRefetchingLoanDetails: () => void;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
}

function LoanOverview({
  userExistsData,
  isLoadingUserExist,
  isRefetchingLoanDetails,
  pageNumber,
  setPageNumber,
}: LoanOverviewProp) {
  const transactionHistoryRef = useRef<HTMLDivElement | null>(null);
  const overviewRef = useRef<HTMLDivElement | null>(null);
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const queryCache = useQueryCache();
  const webStateData = useSelector((state: ReduxStoreModel) => state.webSettingsReducer)

  useEffect(() => {
    mixpanel.track_pageview({"page": "Loan"});
  }, []);

  const { data: loanHistory, isLoading: isLoanHistoryLoading } = useQuery(
    [
      { customerId: userInfo?.customerId, page: pageNumber },
      "fetchWebLoanTransactions",
    ],
    fetchWebLoanTransactions,
    {
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (pageNumber < loanHistory?.totalPages) {
      const nextPage = pageNumber + 1;
      queryCache.prefetchQuery(
        [
          { customerId: userInfo?.customerId, page: nextPage },
          "fetchWebLoanTransactions",
        ],
        fetchWebLoanTransactions
      );
    }
  }, [pageNumber, queryCache, userInfo?.customerId, loanHistory?.totalPages]);

  const scrollToTransactions = (
    elementRef: React.RefObject<HTMLDivElement>
  ) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = (elementRef: React.RefObject<HTMLDivElement>) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.overview_container} ref={overviewRef}>
      {webStateData?.showTransactions && (
        <WebTransactionView
          loanHistory={loanHistory}
          isRefetchingLoanDetails={isRefetchingLoanDetails}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          isLoanHistoryLoading={isLoanHistoryLoading}
        />
      )}

      {!webStateData?.showTransactions && !webStateData?.showLoanHistory && (
        <OverviewComp
          transactionHistoryRef={transactionHistoryRef}
          scrollToTransactions={scrollToTransactions}
          scrollToTop={scrollToTop}
          overviewRef={overviewRef}
          loanHistory={loanHistory}
          isLoanHistoryLoading={isLoanHistoryLoading}
          userExistsData={userExistsData}
          isLoadingUserExist={isLoadingUserExist}
          isRefetchingLoanDetails={isRefetchingLoanDetails}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      )}
    </div>
  );
}

export default LoanOverview;
