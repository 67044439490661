import React from "react";
import { ROUTES } from "../../../../helpers/ROUTES";
import { IoMdArrowRoundBack } from "react-icons/io";

import { useHistory } from "react-router-dom";
import logo from "../../../../assets/loans/loanHistory.svg";
import { formatMoney } from "../../../../helpers/formatCurrency";
import { XLendaLoanStatus } from "../../../../web/components/features/quickRepayment/QuickLoanRepayment";
import { useFetchActiveWidgetLoans } from "../../../../custom-hooks/useFetchActiveLoans";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import loader from "../../../../assets/loadinggif.gif";
import advLogo from "../../../../assets/advancly-logo-full.png";
import { ILoanData } from "../../../../web/components/features/quickRepayment/QuickLoanRepayment";

function QuickLoanRepayment() {
  const history = useHistory();
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const { data: activeLoans, isLoading: isLoadingActiveLoans } =
    useFetchActiveWidgetLoans(
      XLendaLoanStatus.Active,
      borrowerDetails?.customer_id,
      borrowerDetails?.aggregator_id,
    );

  if (isLoadingActiveLoans) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }
  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      {activeLoans?.length > 1 ? (
        <h4>Your Active Loans</h4>
      ) : (
        <h4>Your Active Loan</h4>
      )}

      <div>
        {activeLoans?.length === 0 ? (
          <h4 className="text-center text-muted">
            You currently have no loan records
          </h4>
        ) : (
          activeLoans?.map((loan: ILoanData) => (
            <div key={loan.id}>
              <div
                className="card cursor mb-3"
                onClick={() => {
                  history.push({
                    pathname: ROUTES.widgetQuickLoanRepaymentAmount,
                    state: {
                      loanRef: loan?.loanRef,
                      loanAmount: loan?.loanAmount,
                      id: loan?.id,
                      status: loan?.status,
                      aggregatorId: borrowerDetails?.aggregator_id,
                    },
                  });
                }}
              >
                <div className="card-body d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className=" mr-3">
                      <img
                        src={logo}
                        alt=""
                        className="img-fluid"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                    <div>
                      <p className="font-weight-bold text-dark padding-none">
                        {formatMoney(loan?.loanAmount || 0, "NGN")}
                      </p>

                      <small className="text-muted padding-none">
                        {loan?.loanRef}
                      </small>
                    </div>
                  </div>

                  <span
                    style={{
                      color: "#562eed",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                    }}
                  >
                    Repay
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default QuickLoanRepayment;
