import React, { Dispatch, SetStateAction, useState } from "react";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { useHistory } from "react-router-dom";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { Alert } from "antd";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { SuccessComponent } from "../../../SuccessComponent";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";

interface WidgetAlternativeLoanCompleteScreenProps {
  handleLoanRequestSuccess: any;
  encryptedKey: string;
  handleGoBack: () => void;
  setError: Dispatch<SetStateAction<string>>;
  error: string;
}

function WidgetAlternativeLoanCompleteScreen({
  handleLoanRequestSuccess,
  encryptedKey,
  handleGoBack,
  setError,
  error,
}: WidgetAlternativeLoanCompleteScreenProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const history = useHistory();
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const loanStateData = useSelector(
    (store: ReduxStoreModel) => store.loanDetailsReducer,
  );
  const monoWidgetData = useSelector(
    (state: ReduxStoreModel) => state.monoWidgetDataReducer,
  );

  const handleAction = () => {
    setError("");
    handleGoBack();
  };

  async function handleCreateLoanWithoutBankStatement() {
    setIsLoading(true);

    const payload = {
      effectiveDate: loanStateData?.loan_effective_date,
      bvn_number: borrowerDetails?.bvn_number,
      business_registration_number:
        borrowerDetails?.business_registration_number,
      phone_number: borrowerDetails?.borrower_phone,
      product_id: loanStateData?.product_id,
      aggregator_loan_ref: loanStateData?.aggregator_loan_ref,
      loan_amount: loanStateData?.loan_amount,
      loan_tenure: loanStateData?.loan_tenure,
      repayment_start_date: loanStateData?.repayment_start_date,
      aggregator_id: borrowerDetails?.aggregator_id,
      annual_interest_rate: loanStateData?.annual_interest_rate,
      country_code: borrowerDetails?.country_code,
      customer_id: borrowerDetails?.customer_id,
      customer_bank_account: loanStateData?.customer_bank_account,
      loan_tenor_input_type: loanStateData?.loan_tenor_input_type,
      is_bankstatement_linkage_failed: true,
      bank_statement_provider_response: monoWidgetData?.opened
        ? JSON.stringify({
            opened: monoWidgetData?.opened,
            customer_email: monoWidgetData?.customer_email,
            opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
            is_institution_selected: monoWidgetData?.is_institution_selected,
            selected_institution_name:
              monoWidgetData?.selected_institution_name,
            institution_selected_dateTimeStamp:
              monoWidgetData?.institution_selected_dateTimeStamp,
          })
        : "linked",
    };

    //Below appInsights is tracking the loan  data
    appInsights.trackEvent({
      name: "WidgetCreateLoanData",
      properties: payload,
    });
    try {
      const loanRequestResponse = await postDataWithKey(
        apiEndpoints.processLoan,
        payload,
        //@ts-ignore
        encryptedKey,
      );

      if (loanRequestResponse?.status) {
        setSuccessMsg(loanRequestResponse?.message);
        setShowSuccess(true);

        mixpanel.track("Get Loan", {
          distinct_id: `${borrowerDetails?.email}`,
          "loan amount": `${loanStateData?.loan_amount}`,
          "loan name": `${loanStateData?.loan_name}`,
          "loan tenor": `${loanStateData?.loan_tenure}`,
          product_id: `${loanStateData?.product_id}`,
          "Loan status": `successfully applied`,
          "bank statement linked status": `not linked`,
        });
      }

      if (handleLoanRequestSuccess) {
        handleLoanRequestSuccess(loanRequestResponse);
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: WidgetAlternativeLoanCompleteScreen },
      });
    }
    setIsLoading(false);
  }

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body={`${successMsg}`}
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {error && (
        <div>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      <div>
        <h4>Complete your loan application</h4>
        <br />
        <div
          className="card"
          style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
        >
          <div className="card-body">
            <p>
              We noticed you couldn't link your bank statement. Providing us
              with more financial information improves your credit eligibility.
              To link your bank statement, you can:
            </p>
            <ul>
              <li>
                Retry linking your bank statement by accessing the “Link Bank
                Statement” option via the “Loans” menu on your dashboard or the
                profile page.
              </li>
              <li>
                Alternatively, you can request that your bank statement be sent
                to bankstatement@advancly.com through your mobile banking app or
                by contacting your bank directly.
              </li>
            </ul>
          </div>
        </div>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              role="button"
              aria-roledescription="button"
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleAction}
            >
              Cancel my request
            </p>
            <button
              className="btn btn-primary btn-radius"
              disabled={isLoading}
              onClick={handleCreateLoanWithoutBankStatement}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetAlternativeLoanCompleteScreen;
