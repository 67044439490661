import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Form, Input } from "antd";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";

const LoanRepaymentAmount = () => {
  let location = useLocation<any>();
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Repayment",
      isLoggedIn: true,
    });

    mixpanel.track_pageview({"page": "Repayment - Widget"});

  }, []);

  return (
    <div>
      <div
        onClick={
          () => history.goBack()
          // history.push({
          //   pathname: ROUTES.widgetLoanHistory,
          // })
        }
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loan repayment</h4>
      <br />
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) =>
          history.push({
            pathname: ROUTES.widgetLoanRepaymentMethods,
            state: {
              loan_id: location?.state?.loan_id,
              amount: values.amount,
              loan_ref: location?.state?.loan_ref,
            },
          })
        }
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="amount"
          label="Enter amount"
          rules={[
            { required: true, message: "Required field" },
            {
              pattern: /^\d*[.]?\d*$/,
              message: "Enter numbers from 0 - 9",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              role="button"
              aria-roledescription="button"
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: ROUTES.widgetLoanHistory,
                })
              }
            >
              Go Back
            </p>
            <button className="btn btn-primary btn-radius">Proceed</button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoanRepaymentAmount;
