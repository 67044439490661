import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./TopUp.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import copyIcon from "../../../../assets/web/dashboard/copy.svg";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import Loader from "../../../../components/Loader/Loader";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { WalletDetails } from "../../../../interfaces/wallet";
import { useDispatch, useSelector } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { Alert, Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface TopUpProps {
  walletDetails: WalletDetails;
  refetchFetchWalletdetails: () => void;
}

const schema = yup.object().shape({
  amount: yupValidators.amount,
});

export interface TectToCopyProps {
  bankName: string;
  acctNo: string;
  acctName: string;
}

function TopUp({ walletDetails, refetchFetchWalletdetails }: TopUpProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formattedValue, setformattedValue] = useState("");
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (TopUp.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleButtonClick = () => {
    setCurrentStep((prev) => prev + 1);
  };

  useEffect(() => {
    if (currentStep === 2) {
      const timeoutId = setTimeout(() => {
        handleButtonClick();
        // setCurrentStep(prev => prev + 2)
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [currentStep]);

  const handleRetry = () => {
    setCurrentStep(0);

    dispatch(
      updateWebSettingsAction({ successMsg: { bodyText: "" }, errorMsg: "" })
    );
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const onSubmit = (data: any) => {
    let minimumAmount = 100
    const { amount } = data;
    const numericAmount = Number(amount.replace(/,/g, ''))

    if(numericAmount >= minimumAmount) {
      setAmount(numericAmount);
      handleButtonClick();

      dispatch(
        updateWebSettingsAction({
          errorMsg: "",
        })
      );
    } else {
      dispatch(
        updateWebSettingsAction({
          errorMsg: "Minimum top up amount is #100",
        })
      );
    }
  };

  const copyToClipboard = async ({
    bankName,
    acctNo,
    acctName,
  }: TectToCopyProps) => {
    try {
      const textToCopy = `Bank Name: ${bankName}\nAccount Number: ${acctNo}\nAccount Name: ${acctName}`;
      await navigator.clipboard.writeText(textToCopy);
      dispatch(
        updateWebSettingsAction({
          successMsg: { bodyText: "Account details copied to clipboard" },
        })
      );
    } catch (err) {
      dispatch(updateWebSettingsAction({ errorMsg: "Failed to copy text" }));
    } finally {
      setTimeout(() => {
        dispatch(
          updateWebSettingsAction({
            successMsg: { bodyText: "" },
            errorMsg: "",
          })
        );
      }, 3000);
    }
  };

  const handleCopyButtonClick = () => {
    copyToClipboard({
      acctName: walletDetails?.accountName,
      bankName: walletDetails?.bankName,
      acctNo: walletDetails?.accountNumber,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    let formattedAmount: string;
    if (value === "") {
      formattedAmount = "";
    } else {
      formattedAmount = new Intl.NumberFormat("en-US").format(Number(value));
    }
    setformattedValue(String(formattedAmount));
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";

    refetchFetchWalletdetails();
    dispatch(
      updateWebSettingsAction({
        showTopUp: false,
        successMsg: { bodyText: "" },
        errorMsg: "",
      })
    );
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.stepOne_container}>
            <header className={styles.stepOne_header}>
              <h3>Top-up Amount</h3>
              <p>Enter your top-up amount</p>
            </header>

            <form
              className={styles.stepOne_form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.input_container}>
                <div className={styles.input_label_container}>
                  <label>Amount</label>
                  <input
                    type="text"
                    placeholder="₦"
                    name="amount"
                    ref={register}
                    className={`${styles.input_field}
                            }`}
                    value={formattedValue}
                    onChange={handleChange}
                  />
                </div>
                <span>{errors.amount?.message}</span>
              </div>

              <div className={styles.submit_button}>
                <Button type="primary" htmlType="submit">
                  Proceed
                </Button>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className={styles.stepTwo_container}>
            <img
              src={arrowLeft}
              alt=""
              className={styles.backArrow}
              onClick={handlePrevious}
            />
            <header className={styles.stepTwo_header}>
              <h3>Bank Transfer</h3>
            </header>

            <div className={styles.stepTwo_instruction}>
              <div className={styles.transfer_amount}>
                <p>Transfer:</p>
                <p>₦{amount}</p>
              </div>
              <p className={styles.instruction}>
                Kindly transfer the amount above to this unique account number
                using your mobile banking or ussd.
              </p>
            </div>

            <div className={styles.accountDetails_container}>
              <div className={styles.bank_personal_info}>
                <span className={styles.info}>
                  {walletDetails?.accountName}
                </span>
                <span className={styles.account_number}>
                  {walletDetails?.accountNumber}
                </span>
                <span className={styles.info}>{walletDetails?.bankName}</span>
              </div>

              <img
                src={copyIcon}
                alt=""
                className={styles.copyIcon}
                onClick={handleCopyButtonClick}
              />
            </div>

            <div className={styles.submit_button}>
              <Button
                type="primary"
                disabled={!walletDetails?.accountNumber}
                onClick={handleButtonClick}
              >
                I have Transferred
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.stepThree_container}>
            <Loader message="Checking transaction status..." />
          </div>
        );
      case 3:
        return (
          <div className={styles.stepFour_container}>
            <div className={styles.verification_success}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="75"
                viewBox="0 0 75 75"
                fill="none"
              >
                <path
                  d="M37.4856 0C58.1739 0 74.9712 16.7973 74.9712 37.4856C74.9712 58.1739 58.1739 74.9712 37.4856 74.9712C16.7973 74.9712 0 58.1739 0 37.4856C0 16.7973 16.7973 0 37.4856 0Z"
                  fill="#404040"
                />
                <path
                  d="M37.4637 3.54529C56.1953 3.54529 71.404 18.7539 71.404 37.4856C71.404 56.2172 56.1953 71.4258 37.4637 71.4258C18.7321 71.4258 3.52344 56.2172 3.52344 37.4856C3.52344 18.7539 18.7321 3.54529 37.4637 3.54529Z"
                  fill="white"
                />
                <path
                  d="M22.1348 38.7302L31.3106 47.6975L52.7905 26.2177"
                  stroke="#00C83C"
                  strokeWidth="3.75377"
                />
              </svg>
              <h3>Transaction Complete</h3>
              <p>
                Your balance will reflect your recent top-up once your payment
                has been completed and processed by your bank
              </p>
            </div>

            <div className={styles.submit_button}>
              <Button type="primary" onClick={handleClose}>
                Ok!
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.stepFive_container}>
            <div className={styles.verification_failed}>
              <div className={styles.box}></div>
              <h3>Transaction Failed</h3>
              <p>Something went wrong</p>
            </div>

            <div className={styles.submit_button}>
              <Button type="default" onClick={handleRetry}>
                Try again
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        {webStateData.errorMsg && (
          <div
            style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
          >
            <Alert
              message={webStateData.errorMsg}
              type="error"
              showIcon
              closable
              onClose={() =>
                dispatch(updateWebSettingsAction({ errorMsg: "" }))
              }
            />
          </div>
        )}
        {webStateData.successMsg.bodyText && (
          <div
            style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
          >
            <Alert
              message={webStateData.successMsg.bodyText}
              type="success"
              showIcon
              closable
              onClose={() =>
                dispatch(
                  updateWebSettingsAction({ successMsg: { bodyText: "" } })
                )
              }
            />
          </div>
        )}
        <div className={styles.modal_steps_wrapper}>
          {renderContent()}

          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default TopUp;
