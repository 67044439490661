import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../helpers/yupValidators";
import styles from "./Business.module.scss";
import CustomInputField from "../../../components/CustomHTMLElements/EmbedCustomInputField";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import { Link } from "react-router-dom";
import useFetchCountries from "../../../custom-hooks/useFetchCountries";
import moment from "moment";
import { Alert, Button } from "antd";
import { getData, postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateBorrowerDetailsAction } from "../../../redux/actions";
import { appInsights } from "../../../components/AppInsight/AppInsight";

interface CountryCode {
  country_code?: string;
  currency?: string;
  dial_code?: string;
  id?: number;
  is_active?: number;
  name?: string;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dob: string;
  businessName: string;
  businessRcNumber: string;
  password: string;
  phone_number: string;
}

interface BusinessProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const schema = yup.object().shape({
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  email: yupValidators.email,
  gender: yupValidators.gender,
  dob: yupValidators.dob,
  businessName: yupValidators.businessName,
  businessRcNumber: yupValidators.businessRcNumber,
  password: yupValidators.password,
  phone_number: yupValidators.phone_number,
});

function Business({
  setCurrentStep,
  error,
  setError,
  success,
  setSuccess,
}: BusinessProps) {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );
  const [searchedResults, setSearchedResults] = useState<CountryCode[]>([]);
  const [businessRegNo, setBusinessRegNo] = useState("");
  const [regFieldError, setRegFieldError] = useState("");
  const [generatedBusinessInfo, setGeneratedBusinessInfo] = useState<any>(null);
  const [isLoadingBusinessRegNumber, setIsLoadingBusinessRegNumber] =
    useState(false);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>({
    country_code: "ng",
    dial_code: "234",
  });
  const dispatch = useDispatch();
  const borrowerData = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Onboarding  - (Business.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleRegNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const regex = /^[0-9]*$/;

    if (input === "" || regex.test(input)) {
      setBusinessRegNo(input);
      setRegFieldError("");
    } else {
      setRegFieldError("Please enter only numbers");
    }
  };

  // Gender selection
  const genderList = ["Male", "Female", "Others"];

  const genderDropDown = () => {
    return genderList.map((gender, index) => (
      <option key={index} value={gender}>
        {gender}
      </option>
    ));
  };

  const { data: countryLists } = useFetchCountries();

  const fetchBusinessInfo = async () => {
    setError("");
    setIsLoadingBusinessRegNumber(true);
    try {
      const res = await getData(
        `${apiEndpoints.getBusinessName}?businessRegistrationNumber=${businessRegNo}&countryCode=${borrowerData?.country_code}`,
      );

      setGeneratedBusinessInfo(res.data);
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
    } finally {
      setIsLoadingBusinessRegNumber(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (businessRegNo.length >= 3 && !isLoadingBusinessRegNumber) {
        fetchBusinessInfo();
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [businessRegNo]);

  const filterPrompts = (searchText: string) => {
    const regex = new RegExp(searchText, "i");

    return countryLists.filter((country: CountryCode) => {
      const name = country.name || "";
      const countryCode = country.country_code || "";

      return regex.test(name) || regex.test(countryCode);
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout as NodeJS.Timeout);
    setSearchText(e.target.value);

    //debounce method
    setSearchTimeout(
      setTimeout(() => {
        const searchResult = filterPrompts(e.target.value);
        setSearchedResults(searchResult);
      }, 500),
    );
  };

  const handleCountryDisplay = () => {
    setDisplayCountry((prev) => !prev);
  };

  useEffect(() => {
    const foundCountry = countryLists?.find(
      (country: CountryCode) => country.country_code === borrowerData?.country,
    );
    if (foundCountry) {
      handleCountrySelection(foundCountry);
    }
  }, [countryLists, borrowerData?.country]);

  const handleCountrySelection = (country: CountryCode) => {
    setSelectedCountry({
      country_code: country.country_code?.toLowerCase(),
      dial_code: country.dial_code,
    });
    setDisplayCountry(false);
  };

  function deleteFirstZero(value: string) {
    return value.replace(/^0/, "");
  }

  const currentDate = moment().format("YYYY-MM-DD");

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    gender,
    dob,
    password,
    phone_number,
  }: FormData) => {
    setError("");
    setSuccess("");
    setLoading(true);
    try {
      const reqBody = {
        firstName,
        lastName,
        email,
        phoneNumber:
          borrowerData?.phoneNoCountryDialCode + deleteFirstZero(phone_number),
        gender,
        dateOfBirth: dob,
        bvn: borrowerData?.bvn_number,
        countryCode: borrowerData?.country_code,
        companyName: generatedBusinessInfo?.fullName,
        businessRegistrationNumber: businessRegNo,
        password,
        onboardingType: "Borrower",
        customerType: "Corporate",
        createWallet: true,
        identityNumber: borrowerData?.bvn_number,
        aggregatorId: borrowerData?.aggregator_id,
        isIdentityNumberVerified: true,
        partnerDateRegistered: currentDate,
        funderPeople: [
          {
            first_name: firstName,
            last_name: lastName,
            middle_name: "",
            email,
            gender,
            date_of_birth: dob,
            address: "",
            city: "",
            state: "",
            phone_number,
            country_code: borrowerData?.country_code,
            person_type: "Shareholder",
            identity_number: borrowerData?.bvn_number,
          },
        ],
      };
      await postData(apiEndpoints.customerOnboard, reqBody);
      setSuccess(
        "Account creation is successful. You will be redirected to your dashboard soon, please wait...",
      );

      dispatch(
        updateBorrowerDetailsAction({
          password,
          business_registration_number: businessRegNo,
          company_name: generatedBusinessInfo.fullName,
        }),
      );

      setCurrentStep(5);
    } catch (error) {
      setError("User registration failed");
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Onboarding - (Business.tsx)",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.businessInfo__container}>
      {error && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={success}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess("")}
          />
        </div>
      )}
      <header className={styles.businessInfo_header}>
        <h1>Create an account</h1>
        <p>Enter your business information to continue</p>
      </header>

      <form
        className={styles.businessInfo_form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.regNo}>
          <CustomInputField
            type="text"
            label="Business Reg No"
            placeholder="e.g: 1031929"
            name="businessRcNumber"
            maxLength={10}
            reference={register}
            errors={errors.businessRcNumber}
            onChange={handleRegNoChange}
          />
          {regFieldError && (
            <span className={styles.regError}>{regFieldError}</span>
          )}
        </div>
        <div className={styles.user}>
          {isLoadingBusinessRegNumber && (
            <>
              <div>
                <Loader />
              </div>
              <span>Verifying...</span>
            </>
          )}
        </div>

        <CustomInputField
          type="text"
          label="Business Name"
          placeholder="e.g: Embed holdings"
          name="businessName"
          value={generatedBusinessInfo?.fullName}
          readOnly={borrowerData?.isBvnValidated}
          reference={register}
          errors={errors.businessName}
        />

        <CustomInputField
          type="email"
          placeholder="example@mail.com"
          label="Business/Work Email Address"
          name="email"
          defaultValue={borrowerData?.email}
          readOnly={borrowerData?.isBvnValidated}
          errors={errors.email}
          reference={register}
        />

        <div className={styles.fullName}>
          <CustomInputField
            label="First Name"
            type="text"
            placeholder="e.g: John"
            reference={register}
            name="firstName"
            readOnly={borrowerData?.isBvnValidated}
            defaultValue={borrowerData?.first_name}
            errors={errors.firstName}
          />

          <CustomInputField
            label="Last Name"
            type="text"
            placeholder="e.g: Doe"
            name="lastName"
            readOnly={borrowerData?.isBvnValidated}
            defaultValue={borrowerData?.last_name}
            reference={register}
            errors={errors.lastName}
          />
        </div>

        {!borrowerData?.gender ||
        borrowerData?.gender === "null" ||
        borrowerData?.gender === " " ||
        borrowerData?.gender === "Null" ? (
          <CustomSelectDropdown
            label="Gender"
            name="gender"
            reference={register}
            errors={errors.gender}
          >
            <option value="">Select gender</option>
            {genderDropDown()}
          </CustomSelectDropdown>
        ) : (
          <CustomInputField
            type="text"
            defaultValue={borrowerData?.gender}
            label="Gender"
            name="gender"
            readOnly={!!borrowerData?.gender}
            reference={register}
            errors={errors.gender}
          />
        )}

        <div className={styles.verify_phone}>
          <label htmlFor="phone_number" className={styles.phone_label}>
            Phone Number
          </label>
          <div className={styles.selected_option}>
            <div className={styles.flag} onClick={handleCountryDisplay}>
              <Icon
                icon={`flag:${selectedCountry.country_code}-4x3`}
                className={styles.iconify}
              />
              <span>{selectedCountry.dial_code}</span>
              {displayCountry ? (
                <IoIosArrowUp className={styles.arrow} />
              ) : (
                <IoIosArrowDown className={styles.arrow} />
              )}
            </div>
            <input
              type="tel"
              name="phone_number"
              defaultValue={borrowerData?.borrower_phone}
              readOnly={borrowerData?.isBvnValidated}
              ref={register}
            />
          </div>
          {displayCountry && (
            <div className={styles.options}>
              <input
                type="text"
                value={searchText}
                onChange={handleSearchChange}
                className={styles.search_box}
                placeholder="Search Country Name"
              />
              <ol>
                {searchText
                  ? searchedResults.map((country) => (
                      <li
                        key={country.name}
                        onClick={() => handleCountrySelection(country)}
                      >
                        <div>
                          <Icon
                            icon={`flag:${country.country_code?.toLowerCase()}-4x3`}
                            className={styles.iconify}
                          />
                          <span>{country.name}</span>
                        </div>
                        <span>{country.dial_code}</span>
                      </li>
                    ))
                  : countryLists.map((country: CountryCode) => (
                      <li
                        key={country.name}
                        onClick={() => handleCountrySelection(country)}
                      >
                        <div>
                          <Icon
                            icon={`flag:${country.country_code?.toLowerCase()}-4x3`}
                            className={styles.iconify}
                          />
                          <span>{country.name}</span>
                        </div>
                        <span>{country.dial_code}</span>
                      </li>
                    ))}
              </ol>
            </div>
          )}
          <span className={styles.phoneNumberValidation_error}>
            {errors.phoneNumber?.message}
          </span>
        </div>

        {!borrowerData?.dob ||
        borrowerData?.dob === "null" ||
        borrowerData?.dob === " " ||
        borrowerData?.dob === "Null" ? (
          <div className={styles.alternate_dob_container}>
            <label htmlFor="dob" className={styles.alternate_dob_label}>
              Date of Birth
            </label>
            <input
              type="date"
              name="dob"
              ref={register}
              className={styles.alternate_dob}
            />
            <span className={styles.dobValidation_error}>
              {errors.dob?.message}
            </span>
          </div>
        ) : (
          <CustomInputField
            type="text"
            name="dob"
            placeholder="dd/mm/yy"
            label="Date of Birth"
            defaultValue={borrowerData?.dob}
            readOnly={borrowerData?.isBvnValidated}
            reference={register}
            errors={errors.dob}
          />
        )}

        <CustomInputField
          type="password"
          placeholder="**********"
          name="password"
          label="Password"
          reference={register}
          hasActionButton={true}
          errors={errors.password}
        />

        <div className={styles.submit__btn}>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? "Creating account..." : "Create account"}
          </Button>
          <p className={styles.login}>
            Do you have an account? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Business;
