import React, {
  Fragment,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import styles from "./LoanRequest.module.scss";
import { Button } from "antd";
import plus from "../../../../assets/plus.svg";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import Loader from "../../../../components/Loader/Loader";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { updateLoanDetailsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { useFetchCustomerResidentialInfoStatus } from "../../../../custom-hooks/useFetchCustomerResidentialInfoStatus";

interface ILoanRequestDirectDebit {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  existingDirectDebitAccountData: any;
  directDebitLoading: boolean;
  directdebitError: any;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  residentialInfoStatus: any;
}

const loanRequestDirectDebitschema = yup.object().shape({
  directdebitAccount: yupValidators.directDebitAccount,
});
const LoanRequestDirectDebitWeb = ({
  setCurrentStep,
  existingDirectDebitAccountData,
  directDebitLoading,
  directdebitError,
  error,
  setError,
  residentialInfoStatus,
}: ILoanRequestDirectDebit) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loanRequestDirectDebitschema),
  });

  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const dispatch = useDispatch();
  const [bankDetails, setBankDetails] = useState({
    account_name: "",
    account_number: "",
    bank_name: "",
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestDirectDebitWeb.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleDirectDebitMultipleAccounts = ({
    directdebitAccount,
  }: {
    directdebitAccount: string;
  }) => {
    if (directdebitAccount && residentialInfoStatus) {
      setError("");
      setCurrentStep(3);
    } else if (directdebitAccount && !residentialInfoStatus) {
      setError("");
      setCurrentStep(12);
    }
  };

  const handleSingleDirectDebitAccount = () => {
    dispatch(
      updateLoanDetailsAction({
        customer_bank_account: bankDetails.account_number,
      }),
    );

    if (bankDetails.account_number && residentialInfoStatus) {
      setError("");
      setCurrentStep(3);
    } else if (bankDetails.account_number && !residentialInfoStatus) {
      setError("");
      setCurrentStep(12);
    }
  };

  useEffect(() => {
    if (existingDirectDebitAccountData?.bank_accounts.length === 1) {
      const { account_name, account_number, bank_name } =
        existingDirectDebitAccountData?.bank_accounts[0];
      setBankDetails({
        ...bankDetails,
        account_name: account_name,
        account_number: account_number,
        bank_name: bank_name,
      });
    }
  }, [directDebitLoading]);

  const handleAddNewDirectDebit = () => {
    setCurrentStep(11);
  };

  return (
    <div className={styles.stepEight_container}>
      {error && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      <img
        src={arrowLeft}
        alt=""
        className={styles.arrow}
        onClick={() => setCurrentStep(2)}
      />
      {directdebitError && setError(`${directdebitError}`)}
      <Fragment>
        <header className={styles.stepEight_header}>
          <h3>Direct Debit Account</h3>
          <p className="text-muted">
            Select an account you want us to debit for your repayment
          </p>
        </header>
        {directDebitLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : existingDirectDebitAccountData?.bank_accounts?.length > 1 ? (
          <CustomSelectDropdown
            // defaultValue="Select Direct Debit Account"
            label="Direct Debit Account"
            reference={register}
            // extraLabel={directDebitLoading && "Loading accounts..."}
            placeholder={
              directDebitLoading
                ? "Loading accounts..."
                : "Select Direct Debit Account"
            }
            errors={errors.directdebitAccount}
            name="directdebitAccount"
            onChange={(e: any) =>
              dispatch(
                updateLoanDetailsAction({
                  customer_bank_account: e.target.value,
                }),
              )
            }
          >
            <option value="">Select Direct Debit Account</option>

            {existingDirectDebitAccountData?.bank_accounts?.map(
              ({
                account_name,
                account_number,
                bank_name,
              }: {
                account_name: string;
                account_number: string;
                bank_name: any;
              }) => (
                <option value={account_number} key={account_number}>
                  {account_name}
                  {`  - ${bank_name}  ${account_number}`}
                </option>
              ),
            )}
          </CustomSelectDropdown>
        ) : existingDirectDebitAccountData?.bank_accounts?.length === 1 ? (
          <CustomInputField
            type="text"
            label="Direct Debit Account"
            name="directdebitAccount"
            value={`${bankDetails.account_name} - ${bankDetails.bank_name}  ${bankDetails.account_number}`}
            errors={errors.directdebitAccount}
            readOnly={
              existingDirectDebitAccountData?.bank_accounts?.length === 1
            }
            reference={register}
          />
        ) : null}

        <div className={styles.submit__btn}>
          <Button
            type="primary"
            htmlType="button"
            onClick={
              existingDirectDebitAccountData?.bank_accounts?.length === 1
                ? handleSubmit(handleSingleDirectDebitAccount)
                : handleSubmit(handleDirectDebitMultipleAccounts)
            }
          >
            Proceed
          </Button>
        </div>

        <p
          role="button"
          aria-roledescription="button"
          className="cursor-pointer my-2"
          onClick={handleAddNewDirectDebit}
        >
          {" "}
          <img src={plus} className="" alt="" /> {""}
          Add a new account
        </p>
      </Fragment>
    </div>
  );
};

export default LoanRequestDirectDebitWeb;
