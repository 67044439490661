import React, { useEffect } from "react";
import { RiFileCopyFill } from "react-icons/ri";
import { TopUpComponentProps } from "./TopUpStepOne";
import copy from "copy-text-to-clipboard";
import AppCard from "../../components/AppCard";
import { appInsights } from "../../../../AppInsight/AppInsight";

const TopUpStepTwo = ({
  handleGoBack,
  handleNext,
  walletDetails,
}: TopUpComponentProps) => {
  const handleCopy = () => {
    const content = document?.getElementById("accountNumber")?.textContent;
    if (content) {
      copy(content);
    }
  };

  useEffect(() => {
    appInsights.trackPageView({ name: "TopUpStepTwo.tsx", isLoggedIn: true });
  }, []);

  return (
    <div>
      <div className="title">Bank Transfer</div>
      <p>
        Kindly transfer the amount above to this unique account number using
        your mobile banking or ussd.
      </p>
      {walletDetails?.accountNumber ? (
        <AppCard
          className="d-flex justify-content-between"
          handleCopy={handleCopy}
        >
          <div
            className="d-flex align-items-center justify-content-between w-100"
            onClick={handleCopy}
          >
            <div>
              <p className="mr-1 padding-none" style={{ color: "#8B8699" }}>
                {walletDetails?.accountName}
              </p>
              <p className="title padding-none" id="accountNumber">
                {walletDetails?.accountNumber}
              </p>
              <p className="mr-1 padding-none" style={{ color: "#8B8699" }}>
                {walletDetails?.bankName}
              </p>
            </div>

            <div className="d-flex align-self-end align-items-center cursor-pointer">
              <RiFileCopyFill className="text-primary" />
              <p
                id="accountNumber"
                className="color-mid-purple ml-2 padding-none"
              >
                Copy
              </p>
            </div>
          </div>
        </AppCard>
      ) : (
        <p className="alert alert-danger">No default wallet accounts found. </p>
      )}

      <div className="d-flex justify-content-end" style={{ marginTop: "10%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleNext}
            disabled={!walletDetails?.accountNumber}
          >
            I have transferred
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopUpStepTwo;
